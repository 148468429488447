<template>
  <div fluid>
    <DamCertificateEdit v-if="parseInt(pDatas.service_id) === 1" :pDatas="pDatas" :stepID="stepID"></DamCertificateEdit>
    <DaeImportEdit v-if="parseInt(pDatas.service_id) === 5" :pDatas="pDatas" :stepID="stepID"></DaeImportEdit>
    <DaeProduction v-if="parseInt(pDatas.service_id) === 6" :pDatas="pDatas" :stepID="stepID"></DaeProduction>
    <DaeDistributor v-if="parseInt(pDatas.service_id) === 23" :pDatas="pDatas" :stepID="stepID"></DaeDistributor>
    <CertificateChangeMoa v-if="parseInt(pDatas.service_id) === 17" :pDatas="pDatas" :stepID="stepID"></CertificateChangeMoa>
    <BadcSeed v-if="parseInt(pDatas.service_id) === 19" :pDatas="pDatas" :stepID="stepID"></BadcSeed>
    <BadcFertilizer v-if="parseInt(pDatas.service_id) === 11" :pDatas="pDatas" :stepID="stepID"></BadcFertilizer>
  </div>
</template>
<script>
import DamCertificateEdit from './DamCertificateEdit'
import DaeImportEdit from './DaeImportEdit'
import BadcSeed from './BadcSeed'
import BadcFertilizer from './BadcFertilizer'
import CertificateChangeMoa from './CertificateChangeMoa'
import DaeDistributor from './DaeDistributor'
import DaeProduction from './DaeProduction'

export default {
  props: ['pDatas', 'stepID'],
  components: {
    DamCertificateEdit,
    DaeImportEdit,
    DaeDistributor,
    CertificateChangeMoa,
    DaeProduction,
    BadcFertilizer,
    BadcSeed
  },
  data () {
    return {
      saveLoading: false,
      loadHistory: false
    }
  },
  created () {
  }
}
</script>
<style>
  .b-table-simple {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  .b-tr:hover {
    background-color: #f5f5f5;
  }

  .b-th, .b-td {
    border: 1px solid #ddd;
    padding: 7px;
    background-color: #552323;
  }

  .b-tr:nth-child(even) {
    background-color: #643232;
  }
</style>
