<template>
  <b-container fluid>
    <b-overlay :show="saveLoading">
        <b-col md="12" lg="12" sm="12">
          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <b-form  @submit.prevent="handleSubmit(saveFormData)" >
              <div>
                <b-table-simple bordered>
                    <b-tr>
                      <b-th style="width: 20%;">{{ $t('service_name.service_name') }}</b-th>
                      <b-td style="width: 30%;text-align: left;">{{ getServiceName(service_id) }}</b-td>
                      <b-th style="width: 20%;">{{ $t('li_step.application_id') }}</b-th>
                      <b-td style="width: 30%;text-align: left;">{{ application_id }}</b-td>
                    </b-tr>
                </b-table-simple>
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider name="M/S (En)" vid="name_of_th_3270" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="name_of_th_3270"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                                {{ $t('li_step.messrs_badc') }} {{ $t('globalTrans.enn') }}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="name_of_th_3270"
                              v-model="formData.name_of_th_3270"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="M/S (Bn)" vid="name_of_th_9879" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="name_of_th_9879"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                                {{ $t('li_step.messrs_badc') }} {{ $t('globalTrans.bnn') }}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="name_of_th_9879"
                              v-model="formData.name_of_th_9879"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Proprietor En" vid="owner_name_4139" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="owner_name_4139"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('li_step.proprietor') }} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="owner_name_4139"
                              v-model="formData.owner_name_4139"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Proprietor Name(En)" vid="owner_name_4972" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="owner_name_4972"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                                {{ $t('li_step.proprietor') }} {{ $t('globalTrans.bnn') }}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="owner_name_4972"
                              v-model="formData.owner_name_4972"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Father's Name/Husband's name (En)" vid="fathers_na_6391" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="father__sp_4683"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('li_step.father_or_husband') }} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="father__sp_4683"
                              v-model="formData.father__sp_4683"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Father's Name/Husband's name (Bn)" vid="fathers_na_9299" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="father__sp_3438"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('li_step.father_or_husband') }} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="father__sp_3438"
                              v-model="formData.father__sp_3438"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Mother Name (En)" vid="mother_nam_3314" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="mother_nam_3314"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('globalTrans.mother_name_en') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="mother_nam_3314"
                              v-model="formData.mother_nam_3314"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                   <div class="col-md-6">
                    <ValidationProvider name="Mother Name (Bn)" vid="mother_nam_4416" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="mother_nam_4416"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('globalTrans.mother_name_bn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="mother_nam_4416"
                              v-model="formData.mother_nam_4416"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Address" vid="address_en_6494" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="address_en_6494"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('li_step.business_address') }} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="address_en_6494"
                              v-model="formData.address_en_6494"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Address Bn" vid="address_ba_1907" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="address_ba_1907"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('li_step.business_address') }}  {{ $t('globalTrans.bnn') }}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="address_ba_1907"
                              v-model="formData.address_ba_1907"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <!-- <div class="col-md-6">
                    <ValidationProvider name="Nid" vid="nid___1520" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="national_i_6977"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('globalTrans.nid') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="nid___1520"
                              v-model="formData.nid___1520"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div> -->
                  <div class="col-md-6">
                    <ValidationProvider name="Mobile No" vid="mobile_no_6683" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="mobile_no_6683"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('irri_pump_main.mobile_no') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="mobile_no_6683"
                              v-model="formData.mobile_no_6683"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Division" vid="division" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="division"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            plain
                            v-model="formData.division"
                            :options="divisionList"
                            id="division"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="district_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="formData.district"
                            :options="districtList"
                            id="district"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Upazila" vid="upazilla">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="upazilla"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                          plain
                          v-model="formData.upazilla"
                          :options="upazilaList"
                          id="upazilla"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Attachment" vid="attachment">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('globalTrans.image')"
                        label-for="logo"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{$t('globalTrans.image')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-file
                          id="image"
                          v-on:change="onLeftLogoChange"
                          accept="image/*"
                          v-model="formData.attachment"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-file>
                          <p class="text-danger mt-2" style="font-size: 13px;">{{ $i18n.locale == 'en' ? '[Maximum file size is 2 MB and file format is PNG/JPG/JPEG]' : '[সর্বোচ্চ ফাইল সাইজ 2 MB এবং ফাইল ফরমেট PNG/JPG/JPEG]' }}</p>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                        <b-img v-if="formData.picture_8654" width='150px' :src="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(formData.picture_8654)" fluid alt="Picture"></b-img>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <slot v-if="parseInt(formData.type) !== 1">
                    <div class="col-md-6">
                      <ValidationProvider name="Old Manual Id" vid="old_manual_id" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="old_manual_id"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('externalLrcpn.prev_license_no') }}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="old_manual_id"
                              v-model="formData.old_app.generate_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider name="First Issue Date" vid="issue_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="issue_date"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('externalLrcpn.first_issue') }}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="datepicker"
                              v-model="formData.old_app.issue_date"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider name="Reniew Issue Date" vid="reniew_issue_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="reniew_issue_date"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('externalLrcpn.last_renew_issue_date') }}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="expireDate"
                              v-model="formData.reniew_issue_date"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                    <!-- <div class="col-md-6">
                      <ValidationProvider name="Expire Date" vid="expire_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="expire_date"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('externalLrcpn.last_issue') }}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="expireDate1"
                              v-model="formData.old_app.expire_date"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </div> -->
                  </slot>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12 text-right">
                  <b-button type="submit" variant="primary" class="mr-2 btn-sm">{{ $t('globalTrans.submit') }}</b-button>
                  <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-11')">{{ $t('globalTrans.cancel') }}</b-button>
                </div>
              </div>
            </b-form>
          </ValidationObserver>
        </b-col>
    </b-overlay>
  </b-container>
</template>
<script>
import flatpickr from 'flatpickr'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { changeCertificateDataList, certificateChangeBadcFertilizerStore } from '../../../api/routes'
export default {
  props: ['pDatas', 'stepID'],
  components: {
  },
  data () {
    return {
      saveLoading: false,
      loadHistory: false,
      application_id: this.pDatas.application_id,
      service_id: this.pDatas.service_id,
      note: 0,
      formData: {
        cc_request_id: 0,
        old_app: {
          issue_date: '',
          expire_date: '',
          generate_id: ''
        }
      },
      agricultureProductList: [],
      districtList: [],
      upazilaList: [],
      marketList: [],
      unionList: [],
      history: [],
      upload_photo: '',
      lrcpanBaseUrl: licenseRegistrationServiceBaseUrl
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
    flatpickr('#expireDate', {})
    flatpickr('#expireDate1', {})
  },
  created () {
    this.formData = this.pDatas
  },
  computed: {
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    agricultureProductTypeList: function () {
      return this.$store.state.licenseRegistration.commonObj.agricultureProductTypeList
    }
  },
  watch: {
    'formData.division': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'formData.district': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'formData.upazilla': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    }
  },
  methods: {
    onLeftLogoChange (e) {
       this.getBase64(e.target.files[0]).then(res => {
        this.upload_photo = res
      })
    },
    async imageShow () {
      const pictureData = JSON.parse(this.formData.picture_8654)
      return pictureData[0]
    },
    getFile (value) {
        if (value !== '') {
            const fileData = JSON.parse(value)
            if (fileData[0]) {
                return fileData[0]
            } else {
                return false
            }
        } else {
            return false
        }
    },
    async saveFormData () {
      let result = null
      this.saveLoading = true
      const loadinState = { loading: false, listReload: false }
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formData = new FormData()
      Object.keys(this.formData).map(key => {
        if (key === 'attachment') {
          formData.append(key, this.upload_photo)
        } else {
          formData.append(key, this.formData[key])
        }
      })
      formData.append('app_forwards', JSON.stringify(this.formData.app_forwards))
      formData.append('old_app', JSON.stringify(this.formData.old_app))
      result = await RestApi.postData(licenseRegistrationServiceBaseUrl, certificateChangeBadcFertilizerStore, formData, config)

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.saveLoading = false
        // this.$store.dispatch('licenseRegistration/mutateLicenseRegistrationProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$store.dispatch('licenseRegistration/setLaodList', true)
        this.$bvModal.hide('modal-11')
      } else {
        this.saveLoading = false
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    },
    getBase64 (file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
            imgResult = reader.result
        }
        reader.onerror = function (error) {
            reject(error)
        }
        reader.onloadend = function () {
            resolve(imgResult)
        }
      })
    },
    getServiceName (id) {
      const serviceType = this.$store.state.licenseRegistration.commonObj.serviceNamesList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return serviceType !== undefined ? serviceType.text_bn : ''
      } else {
        return serviceType !== undefined ? serviceType.text_en : ''
      }
    },
    getOrganizationName (id) {
      const serviceType = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return serviceType !== undefined ? serviceType.text_bn : ''
      } else {
        return serviceType !== undefined ? serviceType.text_en : ''
      }
    },
    getOfficeTypeName (id) {
      const officeType = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return officeType !== undefined ? officeType.text_bn : ''
      } else {
        return officeType !== undefined ? officeType.text_en : ''
      }
    },
    getOfficeName (id) {
      const office = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return office !== undefined ? office.text_bn : ''
      } else {
        return office !== undefined ? office.text_en : ''
      }
    },
    async showCertificateData () {
      this.saveLoading = true
      const params = {
        application_id: this.pDatas.application_id,
        service_id: this.pDatas.service_id
      }
      await RestApi.getData(licenseRegistrationServiceBaseUrl, changeCertificateDataList, params).then(response => {
        if (response.success) {
          this.formData = response.data
        }
      })
      this.saveLoading = false
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getDistrictList (divisionId = null) {
      let districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        districtList = districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    }
  }
}
</script>
<style>
  .b-table-simple {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  .b-tr:hover {
    background-color: #f5f5f5;
  }

  .b-th, .b-td {
    border: 1px solid #ddd;
    padding: 7px;
    background-color: #552323;
  }

  .b-tr:nth-child(even) {
    background-color: #643232;
  }
</style>
