<template>
  <b-container fluid>
    <b-overlay :show="saveLoading">
        <b-col md="12" lg="12" sm="12">
          <!-- <pre>{{ formData }}</pre> -->
          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <b-form  @submit.prevent="handleSubmit(saveFormData)" >
              <div>
                <b-table-simple bordered>
                    <b-tr>
                      <b-th style="width: 20%;">{{ $t('service_name.service_name') }}</b-th>
                      <b-td style="width: 30%;text-align: left;">{{ getServiceName(service_id) }}</b-td>
                      <b-th style="width: 20%;">{{ $t('li_step.application_id') }}</b-th>
                      <b-td style="width: 30%;text-align: left;">{{ application_id }}</b-td>
                    </b-tr>
                </b-table-simple>
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider name="M/S (En)" vid="organizati_2112" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="organizati_2112"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                                {{ $t('li_step.messrs') }} {{ $t('globalTrans.enn') }}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="organizati_2112"
                              v-model="formData.organizati_2112"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="M/S (Bn)" vid="organizati_9606" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="organizati_9606"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                                {{ $t('li_step.messrs') }} {{ $t('globalTrans.bnn') }}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="applicant__1497"
                              v-model="formData.organizati_9606"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Proprietor En" vid="applicant__11" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="applicant__11"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('li_step.proprietor') }} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="applicant__11"
                              v-model="formData.applicant__11"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Proprietor Bn" vid="applicant__84" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="applicant__84"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('li_step.proprietor') }} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="applicant__84"
                              v-model="formData.applicant__84"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Address:Vill/Town (En)" vid="business_a_8032" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="business_a_8032"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('globalTrans.address') + ':' + $t('li_step.village_city_name') }} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="company_ad_8032"
                              v-model="formData.company_ad_8032"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                   <div class="col-md-6">
                    <ValidationProvider name="Address:Vill/Town (Bn)" vid="business_a_8633">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="business_a_8633"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('globalTrans.address') + ':' + $t('li_step.village_city_name') }} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="company_ad_8633"
                              v-model="formData.company_ad_8633"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Fertilizer Name" vid="imported_f_4767" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="imported_f_4767"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('li_step.fertilizer_name')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="formData.fertilizer_3689"
                          :options="importList"
                          id="name_of_no_2818"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Brand Name" vid="brand_name" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="brand_name"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('li_step.brand_name') }}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="brand_name"
                              v-model="formData.brand_name_6692"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Attachment" vid="attachment">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('globalTrans.image')"
                        label-for="logo"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{$t('globalTrans.image')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-file
                          id="image"
                          v-on:change="onLeftLogoChange"
                          accept="image/*"
                          v-model="formData.attachment"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-file>
                          <p class="text-danger mt-2" style="font-size: 13px;">{{ $i18n.locale == 'en' ? '[Maximum file size is 2 MB and file format is PNG/JPG/JPEG]' : '[সর্বোচ্চ ফাইল সাইজ 2 MB এবং ফাইল ফরমেট PNG/JPG/JPEG]' }}</p>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        <b-img v-if="formData.image_73" width='150px' :src="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(formData.image_73)" fluid alt="Picture"></b-img>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <slot v-if="parseInt(formData.type) !== 1">
                    <div class="col-md-6">
                      <ValidationProvider name="Old Manual Id" vid="old_manual_id" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="old_manual_id"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('externalLrcpn.prev_license_no') }}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="old_manual_id"
                              v-model="formData.old_app.generate_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider name="First Issue Date" vid="issue_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="issue_date"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('externalLrcpn.first_issue') }}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="datepicker"
                              v-model="formData.old_app.issue_date"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                    <!-- <div class="col-md-6">
                      <ValidationProvider name="Reniew Issue Date" vid="reniew_issue_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="reniew_issue_date"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('externalLrcpn.last_renew_issue_date') }}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="expireDate"
                              v-model="formData.reniew_issue_date"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider name="Expire Date" vid="expire_date" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="expire_date"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('externalLrcpn.last_issue') }}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="expireDate1"
                              v-model="formData.old_app.expire_date"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </div> -->
                  </slot>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12 text-right">
                  <b-button type="submit" variant="primary" class="mr-2 btn-sm">{{ $t('globalTrans.submit') }}</b-button>
                  <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-11')">{{ $t('globalTrans.cancel') }}</b-button>
                </div>
              </div>
            </b-form>
          </ValidationObserver>
        </b-col>
    </b-overlay>
  </b-container>
</template>
<script>
import flatpickr from 'flatpickr'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { changeCertificateDataList, certificateChangeDamStore } from '../../../api/routes'
export default {
  props: ['pDatas', 'stepID'],
  components: {
  },
  data () {
    return {
      saveLoading: false,
      loadHistory: false,
      application_id: this.pDatas.application_id,
      service_id: this.pDatas.service_id,
      note: 0,
      formData: {
        cc_request_id: 0,
        old_app: {
          issue_date: '',
          expire_date: '',
          generate_id: ''
        }
      },
      upload_photo: '',
      lrcpanBaseUrl: licenseRegistrationServiceBaseUrl,
      agricultureProductList: [],
      importList: this.$store.state.licenseRegistration.commonObj.fertilizerName,
      foreignEnlishmentList: this.$store.state.licenseRegistration.commonObj.foreignEnlishmentList,
      history: []
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  created () {
    this.formData = this.pDatas
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    agricultureProductTypeList: function () {
      return this.$store.state.licenseRegistration.commonObj.agricultureProductTypeList
    }
  },
  watch: {
    'formData.division_8106': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    }
  },
  methods: {
    onLeftLogoChange (e) {
       this.getBase64(e.target.files[0]).then(res => {
        this.upload_photo = res
      })
    },
    async imageShow () {
      const pictureData = JSON.parse(this.formData.image_73)
      return pictureData[0]
    },
    getFile (value) {
        if (value !== '') {
            const fileData = JSON.parse(value)
            if (fileData[0]) {
                return fileData[0]
            } else {
                return false
            }
        } else {
            return false
        }
    },
    getBase64 (file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
            imgResult = reader.result
        }
        reader.onerror = function (error) {
            reject(error)
        }
        reader.onloadend = function () {
            resolve(imgResult)
        }
      })
    },
    async saveFormData () {
      let result = null
      this.saveLoading = true
      const loadinState = { loading: false, listReload: false }
      this.formData.attachment = this.upload_photo
      result = await RestApi.postData(licenseRegistrationServiceBaseUrl, certificateChangeDamStore, this.formData)

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.saveLoading = false
        // this.$store.dispatch('licenseRegistration/mutateLicenseRegistrationProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$store.dispatch('licenseRegistration/setLaodList', true)
        this.$bvModal.hide('modal-11')
      } else {
        this.saveLoading = false
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    },
    getServiceName (id) {
      const serviceType = this.$store.state.licenseRegistration.commonObj.serviceNamesList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return serviceType !== undefined ? serviceType.text_bn : ''
      } else {
        return serviceType !== undefined ? serviceType.text_en : ''
      }
    },
    getOrganizationName (id) {
      const serviceType = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return serviceType !== undefined ? serviceType.text_bn : ''
      } else {
        return serviceType !== undefined ? serviceType.text_en : ''
      }
    },
    getOfficeTypeName (id) {
      const officeType = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return officeType !== undefined ? officeType.text_bn : ''
      } else {
        return officeType !== undefined ? officeType.text_en : ''
      }
    },
    getOfficeName (id) {
      const office = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return office !== undefined ? office.text_bn : ''
      } else {
        return office !== undefined ? office.text_en : ''
      }
    },
    async showCertificateData () {
      this.saveLoading = true
      const params = {
        application_id: this.pDatas.application_id,
        service_id: this.pDatas.service_id
      }
      await RestApi.getData(licenseRegistrationServiceBaseUrl, changeCertificateDataList, params).then(response => {
        if (response.success) {
          this.formData = response.data
        }
      })
      this.saveLoading = false
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    }
  }
}
</script>
<style>
  .b-table-simple {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  .b-tr:hover {
    background-color: #f5f5f5;
  }

  .b-th, .b-td {
    border: 1px solid #ddd;
    padding: 7px;
    background-color: #552323;
  }

  .b-tr:nth-child(even) {
    background-color: #643232;
  }
</style>
